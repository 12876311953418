module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://mudita.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["en","pl"],"fallbackLanguage":"en","siteUrl":"https://mudita.com","trailingSlash":"always","redirect":"false","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":false,"nsSeparator":false},"pages":[{"matchPath":"/de/kompakt","languages":["en"],"getLanguageFromPath":false},{"matchPath":"/es/kompakt","languages":["en"],"getLanguageFromPath":false},{"matchPath":"/it/kompakt","languages":["en"],"getLanguageFromPath":false},{"matchPath":"/fr/kompakt","languages":["en"],"getLanguageFromPath":false},{"matchPath":"/sleep-better","languages":["en"],"getLanguageFromPath":false},{"matchPath":"/better-sleep","languages":["en"],"getLanguageFromPath":false},{"matchPath":"/system/redirect","languages":["en"],"getLanguageFromPath":false},{"matchPath":"/ifa2024","languages":["en"],"getLanguageFromPath":false},{"matchPath":"/:lang?/search","languages":["en","pl"],"getLanguageFromPath":true},{"matchPath":"/:lang?/confirmation","languages":["en","pl"],"getLanguageFromPath":true},{"matchPath":"/:lang?/community/blog/(.*)","languages":["en"],"getLanguageFromPath":false},{"matchPath":"/:lang?/(.*)","getLanguageFromPath":true,"languages":["en","pl"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Mudita","short_name":"Mudita","start_url":"/","background_color":"#FFFFFF","theme_color":"#84A9CF","display":"minimal-ui","icon":"src/images/icons/mudita_sign_dark.svg","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f13494c3ab8f0b57733b68466f529498"},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TVTDL5Q","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
